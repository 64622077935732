import './Css/Auth.css'
import RegistrationForm from '../Components/RegistrationForm'

export default function Registration (){

    return(
        <>
        <RegistrationForm/>
        <div className='login-background'></div>
        </>
    )
}